<script setup lang="ts">
import { computed, ref } from 'vue'
import modal from '/~/core/mdl'
import { isEmptyText } from '/~/utils/format'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseMdl, { ModalWidth } from '/~/components/mdl/mdl-popup.vue'

const props = withDefaults(
  defineProps<{
    width?: ModalWidth
    headerHidden?: boolean
    headerLabel?: string
    image?: object
    title?: string
    content?: string
    acknowledgeText?: string
    buttonLabel?: string
    action?: object
    onConfirm: (dontShowAgain: boolean) => void
  }>(),
  {
    image: undefined,
    width: 'sm',
  }
)

const dontShowAgain = ref(false)
const hasContent = computed(() =>
  Boolean(props.content && !isEmptyText(props.content))
)

function hide() {
  modal.hide()
}

function gotIt() {
  props.onConfirm(dontShowAgain.value)
  hide()
}
</script>

<template>
  <base-mdl
    :width="width"
    border-radius="xl"
    layout="v2"
    :title="headerLabel"
    :padding="false"
    adding="none"
    fullscreen="mobile"
    :header-hidden="headerHidden"
    header-border
  >
    <div class="relative flex min-h-full flex-col">
      <div v-if="image.image" class="relative w-full">
        <img :src="image.image" :alt="image.alt" class="w-full" />
        <span
          class="absolute left-0 top-0 ml-6 mt-5 flex flex-col items-start justify-start"
        >
          <span v-html="title"></span>
        </span>
      </div>
      <div class="space-y-6 p-6">
        <div v-if="hasContent" v-html="content" />
        <div v-if="acknowledgeText" class="flex items-center justify-center">
          <base-checkbox v-model="dontShowAgain">
            {{ acknowledgeText }}
          </base-checkbox>
        </div>
        <div v-if="buttonLabel" class="flex justify-center">
          <base-action
            v-if="action.enabled"
            v-bind="action"
            :label="image.alt"
            origin="banner"
            appearance="link"
            class="flex w-full max-w-96 justify-center"
            @click="gotIt"
          >
            <base-button class="w-full max-w-96">{{ buttonLabel }}</base-button>
          </base-action>
        </div>
      </div>
    </div>
  </base-mdl>
</template>
